.page-grid {
  grid-area: page;
  display: grid;
  grid-template-rows: repeat(3, minmax(min-content, max-content));
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    "blank1 Card-1 blank2"
    "blank1 Preview-1 blank2"
    "blank1 Carousel-1 blank2";

  & > :nth-child(1) {
    grid-area: Card-1;
    align-self: center;
    justify-self: center;
  }

  & > :nth-child(2) {
    margin-top: 5rem;
    grid-area: Preview-1;
    align-self: center;
    justify-self: center;
  }

  & > :nth-child(3) {
    margin-top: 5rem;
    grid-area: Carousel-1;
    align-self: center;
    justify-self: center;
    margin-bottom: 0;

    @media (--viewport-s) {
      margin-bottom: 10rem;
    }
  }

  @media (--viewport-s) {
    grid-template-rows: repeat(2, minmax(min-content, max-content));
    grid-template-columns: auto 1fr 1fr auto;
    grid-row-gap: 10rem;
    grid-template-areas:
      "blank1 Card-1 Preview-1 blank2"
      "blank1 Carousel-1 Carousel-1 blank2";

    & > :nth-child(n) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.video {
  all: unset;
  border: 3px solid var(--selected-font-color);
  border-radius: 10px;
}

.image {
  max-width: 90% !important;

  @media (--viewport-s) {
    max-width: 60% !important;
  }
}

.carousel-image-container {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;

  & > :nth-child(1) {
    color: var(--selected-font-color);
    max-width: 8rem;
    max-height: 8rem;
    margin-bottom: 1rem;
  }

  @media (--viewport-s) {
    margin-top: 1.5rem;
  }
}

.slider-content-wrapper {
  display: grid;

  & > :nth-child(n) {
    width: 100%;
    justify-self: center;
    align-self: center;
  }

  & > :nth-child(2) {
    margin-bottom: 0.5rem;

    @media (--viewport-s) {
      margin-bottom: 3rem;
    }
  }

  & > :nth-child(3) {
    margin-bottom: 2rem;
    max-width: 16rem;
  }
}
