/* stylelint-disable no-descending-specificity */
.layout-grid {
  display: grid;
  grid-template-rows: repeat(3, minmax(min-content, max-content));
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    "blank1 header blank2"
    "blank1 main blank2"
    "blank1 footer blank2"
    "blank1 installBanner blank2"
    "blank1 overlay blank2";

  & > :nth-child(1) {
    grid-area: header;
    width: 100vw;
  }

  & > :nth-child(2) {
    padding-top: 7rem;
    grid-area: main;
    align-self: center;
    justify-self: center;
    max-width: 300rem;
    width: 90vw;

    @media (--viewport-l) {
      width: 80vw;
      padding-top: 10rem;
    }

    @media (--viewport-xl) {
      width: 70vw;
      padding-top: 12rem;
    }
  }

  & > :nth-child(3) {
    grid-area: footer;
    align-self: center;
    justify-self: center;
    max-width: 300rem;
    width: 90vw;

    @media (--viewport-l) {
      width: 80vw;
    }

    @media (--viewport-xl) {
      width: 70vw;
    }
  }

  & > :nth-child(4) {
    grid-area: installBanner;
  }

  & > :nth-child(5) {
    grid-area: overlay;
  }
}

.layout-header {
  & > :nth-child(1) {
    max-width: 300rem;

    @media (--viewport-m) {
      width: 90vw;
    }

    @media (--viewport-l) {
      width: 80vw;
    }

    @media (--viewport-xl) {
      width: 70vw;
    }
  }
}

.layout-footer {
  display: grid;
  grid-template-rows: repeat(3, minmax(min-content, max-content));
  grid-template-columns: auto 1fr auto;
  grid-row-gap: 5rem;
  padding-top: 5rem;
  padding-bottom: 2rem;
  grid-template-areas:
    "blank1 card-1 blank2"
    "blank1 footer blank2"
    "blank1 copyright blank2";

  & > :nth-child(n) {
    align-self: center;
    justify-self: center;
  }

  & > :nth-child(1) {
    grid-area: card-1;
  }

  & > :nth-child(2) {
    grid-area: footer;
  }

  & > :nth-child(3) {
    text-align: center;
    grid-area: copyright;
  }

  @media (--viewport-l) {
    padding-top: 10rem;
  }

  @media (--viewport-xl) {
    padding-top: 15rem;
  }
}

.layout-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1111;
  left: 0;
  top: 0;
  visibility: hidden;
  transition: 0.3s;
}

.layout-overlay-open {
  background-color: var(--selected-font-grey-color);
  opacity: 0.3;
  visibility: visible;
}
